<template>
  <div>
    <b-card>
      <div slot="header">
        <strong>고객별 구매내역 검색</strong>
      </div>
      <!--<b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list">
            <i class="fa fa-search"></i> 검색
          </b-button>
        </b-input-group-prepend>
        <b-form-input id="search" type="text" placeholder="" v-model="form.search" @keypress.enter.prevent.stop="list" v-focus></b-form-input>
      </b-input-group>-->
      <b-row>
        <b-col cols="3">
          <small>주문기간</small><br/>
          <b-form inline>
            <date-input v-model="form.sd" @change="v=>{form.sd=v}" @enter="list"></date-input>
            &nbsp;∽&nbsp;
            <date-input v-model="form.ed" @change="v=>{form.ed=v}" @enter="list"></date-input>
          </b-form>
        </b-col>
        <b-col cols="3">
          <small>정렬순서</small><br/>
          <b-form-radio-group class="col-form-label" v-model="form.sort" :options="[
              {text: '구매금액', value: 'amount'},
              {text: '구매횟수', value: 'count'},
            ]"></b-form-radio-group>
        </b-col>
      </b-row>
      <div class="clearfix">
        <b-button class="m-1" variant="primary" @click="list">검색</b-button>
      </div>
    </b-card>
    <c-table :table-data="items" :fields="fields" :perPage.sync="perPage" :isBusy="isBusy" :getMoreBusy="getMoreBusy" :hasMore="hasMore"
             :caption="items.length + ' 명'" @get-more="getMore"></c-table>
  </div>
</template>
<style scoped>
.w-120 {
  width: 120px !important;
  text-align: center
}
</style>
<script>
import dateInput from '@/views/modules/DateInput.vue'
import * as moment from 'moment-timezone';

export default {
  name: 'CustomerList',
  components: {dateInput},
  data() {
    return {
      form: {
        search: '',
        sd: '2021-01-01',
        ed: moment().format('YYYY-MM-DD'),
        sort: 'amount',
        limit: 100,
        skip: 0,
      },
      lastForm: {},
      items: [],
      item: {},
      fields: [
        {key: 'html1', label: '고객번호', class: 'text-center'},
        {key: 'html4', label: '고객정보', class: 'text-center'},
        {key: 'html2', label: '구매금액<br/><span class="badge badge-light">구매수</span>', style: 'width:120px', class: 'text-center'},
        {key: 'html3', label: '최근구매내역', class: 'text-center'},
      ],
      perPage: 20,
      isBusy: false,
      getMoreBusy: false,
      hasMore: false,
      abortController: null,
    }
  },
  async created() {
    this.list();
  },
  async beforeDestroy() {
    if (this.abortController) this.abortController.abort();
  },
  methods: {
    async list(more) {
      let form;
      if (more === true) {
        if (this.getMoreBusy) return; // 이전요청을 기다린다
        form = this.lastForm;
        form.skip += form.limit;
        this.getMoreBusy = true;
      } else {
        form = this.form;
        this.lastForm = this.$utils.clone(form);
        this.items.splice(0, this.items.length);
        this.isBusy = true;
      }

      if (this.abortController) this.abortController.abort();
      this.abortController = new AbortController();
      let j;
      try {
        j = await this.$api.postJson('/customer/list', {form: {...form}}, {signal: this.abortController.signal});
      } catch (e) {
        if (more !== true) this.items = [];
        return;
      }
      this.abortController = null;

      j.list.forEach(e => {
        e.html1 = `<a href="${this.ADMIN_URL}/#/mall/users?m_no=${e.m_no}" target="_blank">${e.m_no}</a><br/>`;
        e.html2 = `${this.$utils.comma(e.amount)}<br/><span class="badge badge-light">${e.count}</span>`;
        e.html3 = e.orders.slice(0, 15).map(e => `<div class="d-inline-block mr-1">
            <img src="${e.img_i}" style="height:60px" /><br/>
            <a href="/#/goods/${e.goodsno}" target="_blank" class="badge badge-primary">${e.goodsno}</a><br/>
            <a href="/#/deliveryBoard/${e.ordno}/${e.sno}" target="_blank" class="badge badge-light">${moment(e.orddt).format('YY-MM-DD')}</a><br/>
            <a href="/#/deliveryBoard/${e.ordno}/${e.sno}" target="_blank" class="badge badge-light">${this.$utils.comma(e.price)} 원</a><br/>
            </div>`).join('');
        e.html4 = `${e.m_id}<br/>${e.name}<br/><span class="badge badge-light">${e.sex === 'm' ? '남성' : '여성'}</span>`;
      });

      this.hasMore = j.hasMore;
      if (more === true) {
        this.items = this.items.concat(j.list);
        this.getMoreBusy = false;
      } else {
        this.items = j.list;
        this.isBusy = false;
      }
    },
    getMore() {
      this.list(true);
    },
  }
}
</script>
